import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FiFile, FiCalendar } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CatDialogEdit from "./categoryEditDialog";

const TableCategory = ({ data, refreshData }) => {
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { REACT_APP_API_ENDPOINT } = process.env;

  const openDialog = (item) => {
    setSelectedItem(item);
    setShowDialog(true);
  };

  const closeDialog = () => {
    setSelectedItem(null);
    setShowDialog(false);
  };
  const deleteItem = async (name) => {
    if (name) {
      try {
        const response = await axios.delete(
          `${REACT_APP_API_ENDPOINT}/v1/supplyChain/supplierCategory/${name}`
        );
        console.log("Response:", response);
        alert("Successfully Deleted");
        refreshData();
      } catch (error) {
        console.error("Error deleting data:", error);
        alert("Failed to Delete");
      }
    }
  };

  // Use the provided data prop if available, otherwise fallback to dummy data
  const tableData = data;

  return (
    <div className="bg-[#FFF] rounded-[20px] p-4 w-[95%] max-h-[500px] overflow-y-auto mt-8 mb-8">
      <table className="w-full border-collapse border-none min-h-[250px]max-h-[50%] overflow-y-auto">
        <thead>
          <tr className="text-left bg-white text-primary font-bold">
            <th className="px-6 py-6 ">Category ID</th>
            <th className="px-6 py-6 ">Category Name</th>
            <th className="px-6 py-6 "> Upadated Date</th>
            <th className="px-60 py-6 "></th>
            <th className="px-6 py-6 "></th>
          </tr>
        </thead>
        <tbody className="font-bold">
          {tableData.map((item, index) => (
            <tr
              key={index}
              className={`${
                index !== tableData.length - 1 ? "border-b border-gray-300" : ""
              }`}
            >
              <td className="px-6 py-2">{item.supplier_category_id}</td>
              <td className="px-6 py-2">{item.supplier_category_name}</td>
              <td className="px-6 py-2">
                {item.supplier_category_update_date}
              </td>
              <td className="px-60 py-2">
                <button
                  className="text-black bg-gray-300 rounded-[22px] min-w-[55px]"
                  onClick={() => openDialog(item)}
                >
                  Edit
                </button>
              </td>
              <td className="px-6 py-2">
                <button
                  className="bg-primary text-white min-w-[65px] rounded-[22px] "
                  onClick={() => deleteItem(item.supplier_category_id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showDialog && (
        <CatDialogEdit
          onClose={closeDialog}
          item={selectedItem}
          refreshData={refreshData}
        />
      )}
    </div>
  );
};

export default TableCategory;
