import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TableSupplier from "./supplierTable";
import axios from "axios";
import IdGenerate from "../../../../utils/id_generate";
import usePermissions from "../../../../components/permissions/permission";

const Supply_Edit = () => {
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [table, setTable] = useState([]);
  const [supplierNumber, setSupplierNumber] = useState(IdGenerate("SUP"));
  const [supplierName, setSupplierName] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [faxNo, setFaxNo] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [vatNo, setVatNo] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [date, setDate] = useState(new Date());
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;

  const refreshData = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/supplierCategory/status/ACTIVE`
      );
      setData(response.data.length > 0 ? response.data : []);
    } catch (error) {
      console.error("Error fetching data:", error);
      setData([]);
    }
  };

  const tableDate = async () => {
    try {
      const tdata = await axios.get(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/supplier/status/ACTIVE`
      );
      setTable(tdata.data.length > 0 ? tdata.data : []);
    } catch (error) {
      console.error("Error fetching data:", error);
      setTable([]);
    }
  };

  const postFormData = async () => {
    const body = {
      supplier_id: supplierNumber,
      supplier_category_id: selectedCategory,
      supplier_name: supplierName,
      supplier_address: address,
      supplier_contact_no: phoneNo,
      supplier_fax: faxNo,
      supplier_email: emailAddress,
      supplier_vat_no: vatNo,
      supplier_update_date: date.toLocaleDateString("en-GB"),
      supplier_update_user_id: "EMP-000001",
    };
    try {
      const response = await axios.post(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/supplier/add`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      alert("Supplier successfully added");
      reset();
      tableDate();
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to add Supplier");
    }
  };

  const searchSuppliers = async (query) => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/supplier/search/${query}`
      );
      setSearchResults(response.data.length > 0 ? response.data : []);
    } catch (error) {
      console.error("Error fetching search results:", error);
      setSearchResults([]);
    }
  };

  useEffect(() => {
    refreshData();
    tableDate();
  }, []);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    if (e.target.value.trim() !== "") {
      searchSuppliers(e.target.value.trim());
    } else {
      setSearchResults([]);
    }
  };

  const reset = () => {
    setSupplierNumber(IdGenerate("SUP"));
    setSupplierName("");
    setAddress("");
    setPhoneNo("");
    setFaxNo("");
    setEmailAddress("");
    setVatNo("");
    setSelectedCategory("");
    setDate(new Date());
    setSearch("");
    setSearchResults([]);
  };

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[32px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
        Suppliers
      </p>
      <p className="text-[32px] font-sans ml-24 mt-4 font-bold text-[#071C50] ">
        Add Suppliers
      </p>

      <div className="flex justify-between mt-10 mx-6">
        <div className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card min-w-[25%] ml-[65px] w-[90%] h-[20%]">
          <div className="min-h-[110px] min-w-[150px] ">
            <h2 className="text-[26px] text-[#797C80] font-semibold relative">
              Enter Supplier Details
            </h2>
          </div>
          <div className="grid grid-cols-2 gap-6 ">
            <div className="flex items-center">
              <label className="mr-[10%] text-black font-poppins font-bold text-xl">
                Supplier Number:
              </label>
              <input
                readOnly
                type="text"
                placeholder="Enter ID"
                value={supplierNumber}
                onChange={(e) => setSupplierNumber(e.target.value)}
                className="border border-gray-300 rounded-lg px-2 py-2 w-full text-xl font-bold"
              />
            </div>
            <div className="flex items-center">
              <label className="mr-[10%] text-black font-poppins font-bold text-xl">
                Supplier Name:
              </label>
              <input
                type="text"
                placeholder="Enter ID"
                value={supplierName}
                onChange={(e) => setSupplierName(e.target.value)}
                className="border border-gray-300 rounded-lg px-2 py-2 w-full text-xl font-bold"
              />
            </div>
            <div className="flex items-center">
              <label className="mr-[10%] text-black font-poppins font-bold text-xl">
                Address:
              </label>
              <input
                type="text"
                placeholder="Enter Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className="border border-gray-300 rounded-lg px-2 py-2 w-full text-xl font-bold"
              />
            </div>
            <div className="flex items-center">
              <label className="mr-[10%] text-black font-poppins font-bold text-xl">
                Phone Number:
              </label>
              <input
                type="text"
                placeholder="Enter Phone NO"
                value={phoneNo}
                onChange={(e) => setPhoneNo(e.target.value)}
                className="border border-gray-300 rounded-lg px-2 py-2 w-full text-xl font-bold"
              />
            </div>
            <div className="flex items-center">
              <label className="mr-[10%] text-black font-poppins font-bold text-xl">
                FAX No:
              </label>
              <input
                type="text"
                placeholder="Enter FAX NO"
                value={faxNo}
                onChange={(e) => setFaxNo(e.target.value)}
                className="border border-gray-300 rounded-lg px-2 py-2 w-full text-xl font-bold"
              />
            </div>
            <div className="flex items-center">
              <label className="mr-[10%] text-black font-poppins font-bold text-xl">
                Email Address:
              </label>
              <input
                type="text"
                placeholder="Enter Email Address"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
                className="border border-gray-300 rounded-lg px-2 py-2 w-full text-xl font-bold"
              />
            </div>
            <div className="flex items-center">
              <label className="mr-[10%] text-black font-poppins font-bold text-xl">
                VAT No:
              </label>
              <input
                type="text"
                placeholder="Enter VAT NO"
                value={vatNo}
                onChange={(e) => setVatNo(e.target.value)}
                className="border border-gray-300 rounded-lg px-2 py-2 w-full text-xl font-bold"
              />
            </div>
            <div className="flex items-center">
              <label className="mr-[10%] text-black font-poppins font-bold text-xl">
                Category:
              </label>
              <select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
                className="border border-gray-300 rounded-lg px-2 py-2 w-full text-xl font-bold"
              >
                <option value="">Select Category</option>
                {data.map((category) => (
                  <option
                    key={category.supplier_category_id}
                    value={category.supplier_category_id}
                  >
                    {category.supplier_category_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end mr-6 mt-6">
        <button
          className="text-lime-500 bg-transparent border border-neutral-800 rounded-[22px] font-bold min-w-20 mr-2"
          onClick={reset}
        >
          Reset
        </button>
        {hasPermission(2630) && (
          <button
            className=" text-white bg-primary rounded-[22px] font-bold min-w-20"
            onClick={postFormData}
          >
            Add
          </button>
        )}
      </div>
      <div className="flex flex-col font-poppins font-bold mt-10 ml-[90px]">
        <form className="form relative">
          <button className="absolute left-2 -translate-y-1/2 top-1/2 p-1">
            <svg
              className="w-5 h-5 text-gray-700"
              aria-labelledby="search"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              height="16"
              width="17"
            >
              <path
                stroke-linejoin="round"
                stroke-linecap="round"
                stroke-width="1.333"
                stroke="currentColor"
                d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
              ></path>
            </svg>
          </button>
          <input
            type="text"
            required=""
            placeholder="Search..."
            value={search}
            onChange={handleSearchChange}
            className="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
          />
          <button
            className="btn btn-primary text-[#797C80] hover:bg-gray-100"
            onClick={reset}
          >
            RESET
          </button>
        </form>
        <TableSupplier
          data={searchResults.length > 0 ? searchResults : table}
          category={data}
          tableDate={() => tableDate()}
        />
      </div>
    </div>
  );
};

export default Supply_Edit;
