import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Notification from "../../../components/notification/notification";

const Add_sample_aloocation = ({ togglePopup, resetTable }) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_QUALITY;

  const [sampleDetails, setSampleDetails] = useState({
    sample_type: "",
    sample_id: "",
    sample_name: "",
    amount: "",
    received_date: "",
    duration: "",
    expiry_date: "",
    material_type: "",
  });

  const [isResetConfirmationOpen, setIsResetConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);
  const [notification, setNotification] = useState({ message: "", type: "" });

  // set sample  details
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSampleDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleOpenResetConfirmation = () => {
    setIsResetConfirmationOpen(true);
  };

  const handleOpenSaveConfirmation = () => {
    setIsSaveConfirmationOpen(true);
  };

  const handleCloseSaveConfirmation = () => {
    setIsSaveConfirmationOpen(false);
  };

  const handleCloseResetConfirmation = () => {
    setIsResetConfirmationOpen(false);
  };

  // Save Form
  const handleSaveForm = async () => {
    console.log(sampleDetails);
    try {
      const response = await axios.post(
        `${backendUrl}/qualitySamples/addSamples`,
        sampleDetails,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response.data);

      setNotification({
        message: "Sample details saved successfully!",
        type: "success",
      });

      resetTable(true);
    } catch (error) {
      console.error(error);
      setNotification({
        message: "Error saving sample details. Please try again.",
        type: "error",
      });
    }
    setIsSaveConfirmationOpen(false);
  };

  // use State Reset
  const handleResetForm = () => {
    setSampleDetails({
      sample_type: "",
      sample_id: "",
      sample_name: "",
      amount: "",
      received_date: "",
      material_type: "",
      duration: "",
      expiry_date: "",
    });
    setIsResetConfirmationOpen(false);
  };

  const getLastSampleId = async () => {
    const value = sampleDetails.sample_type;

    if (!value) return;

    try {
      const response = await axios.get(
        `${backendUrl}/qualitySamples/getLastSampleID`,
        {
          params: {
            sample_type: value,
          },
        }
      );

      // Extract the lastSampleId from the response
      const lastSampleId = response.data.lastSampleId;

      // Check if the lastSampleId has a valid format
      if (lastSampleId && /^[A-Z]+\d{3}$/.test(lastSampleId)) {
        // Separate the letter prefix and the numeric part
        const prefix = lastSampleId.match(/^[A-Z]+/)[0]; // Match all letters at the start
        const numberPart = parseInt(lastSampleId.match(/\d{3}$/)[0], 10); // Match the numeric part and convert to integer

        // Increment the numeric part by 1
        const newNumberPart = numberPart.toString().padStart(3, "0"); // Ensure it's always 3 digits

        // Construct the new sample ID
        const newSampleId = `${prefix}${newNumberPart}`;

        // Update the sample details with the new sample ID
        setSampleDetails((prevDetails) => ({
          ...prevDetails,
          sample_id: newSampleId,
        }));

        console.log("New Sample ID:", newSampleId);
      } else {
        console.error("Invalid format for last sample ID:", lastSampleId);
      }
    } catch (error) {
      console.error("Error fetching sample details:", error);
    }
  };

  // set sample id
  const handleSampleID = (e) => {
    const data = e.target.value;
    setSampleDetails((prevDetails) => ({
      ...prevDetails,
      sample_type: data,
    }));
  };

  // Handle Notification Close
  const handleCloseNotification = () => {
    setNotification({ message: "", type: "" });
  };
  useEffect(() => {
    getLastSampleId();
  }, [sampleDetails.sample_type]);

  return (
    <div className="flex items-center justify-center h-full bg-gray-100">
      <div className="relative w-full p-8 bg-white rounded-lg">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold">Add Sample Allocation</h2>
          <button
            type="button"
            onClick={togglePopup}
            className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
          >
            <IoIosCloseCircleOutline />
          </button>
        </div>

        {/* Notification Alert */}
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={handleCloseNotification}
        />
        <form>
          <div className="items-center gap-8 mt-5">
            <div className="grid grid-cols-2 gap-y-[30px] gap-x-[60px] text-[20px]">
              {/* Sample Type */}
              <div>
                <label className="block text-gray-700">Sample Type*</label>
                <select
                  name="sample_type"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={sampleDetails.sample_type}
                  onChange={handleSampleID}
                >
                  <option value="" disabled>
                    Select Sample Type
                  </option>
                  <option value="BULK">BULK</option>
                  <option value="TENDER">TENDER</option>
                </select>
              </div>

              {/* Sample ID */}
              <div>
                <label className="block text-gray-700">Sample ID*</label>
                <input
                  type="text"
                  name="sample_id"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={sampleDetails.sample_id}
                  onChange={handleInputChange}
                  readOnly
                />
              </div>
              {/* Sample Name */}
              <div>
                <label className="block text-gray-700">Sample Name*</label>
                <input
                  type="text"
                  name="sample_name"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={sampleDetails.sample_name}
                  onChange={handleInputChange}
                />
              </div>
              {/* Amount */}
              <div>
                <label className="block text-gray-700">Amount*</label>
                <input
                  type="text"
                  name="amount"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={sampleDetails.amount}
                  onChange={handleInputChange}
                />
              </div>
              {/*Tender/ Bulk received date */}
              <div>
                <label className="block text-gray-700">
                  Tender/ Bulk received date*
                </label>
                <input
                  type="date"
                  name="received_date"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={sampleDetails.received_date}
                  onChange={handleInputChange}
                />
              </div>
              {/* Raw Material Type */}
              <div>
                <label className="block text-gray-700">
                  Raw Material Type*
                </label>
                <select
                  name="material_type"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={sampleDetails.material_type}
                  onChange={handleInputChange}
                >
                  <option value="">Raw Material Type</option>
                  <option value="OIL">OIL</option>
                  <option value="DRY">DRY</option>
                  <option value="FRESH">FRESH</option>
                </select>
              </div>
              {/* Duration */}
              <div>
                <label className="block text-gray-700">Duration*</label>
                <select
                  name="duration"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={sampleDetails.duration}
                  onChange={handleInputChange}
                >
                  <option value="">Select Sample Type</option>
                  <option value="one & half year">one & half year</option>
                  <option value="02 years">02 years</option>
                  <option value="03 years">03 years</option>
                  <option value="10 years">10 years</option>
                  <option value="20 years">20 years</option>
                </select>
              </div>

              {/*Expiry date */}
              <div>
                <label className="block text-gray-700">Expiry Date*</label>
                <input
                  type="date"
                  name="expiry_date"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={sampleDetails.expiry_date}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </form>

        <div className="mt-5">
          <div className="flex items-center justify-between px-4 py-2">
            <div></div>
            <div className="flex gap-4">
              <button
                type="button"
                className="px-4 py-2 text-green-600 bg-white border border-black rounded-xl"
                onClick={handleOpenResetConfirmation}
              >
                Reset
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-white bg-green-600 border border-black rounded-xl"
                onClick={handleOpenSaveConfirmation}
              >
                Save
              </button>
            </div>
          </div>
        </div>

        {isResetConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Reset</h2>
              <p>
                Are you sure you want to reset the form? This action cannot be
                undone.
              </p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-red-500 rounded-xl"
                  onClick={handleResetForm}
                >
                  Yes, Reset
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseResetConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {isSaveConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Save</h2>
              <p>Are you sure you want to save the form data?</p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-green-500 rounded-xl"
                  onClick={handleSaveForm}
                >
                  Yes, Save
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseSaveConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Add_sample_aloocation;
