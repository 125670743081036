import React, { useState, useEffect } from "react";
import axios from "axios";

const CatDialogEdit = ({ onClose, item, refreshData }) => {
  const [categoryName, setCategoryName] = useState(item.supplier_category_name);
  const [updatedDate, setUpdatedDate] = useState(
    new Date(item.supplier_category_update_date).toISOString().slice(0, 10)
  );
  const { REACT_APP_API_ENDPOINT } = process.env;

  const handleClickOutside = (event) => {
    const dialog = document.getElementById("dialogBox");
    if (dialog && dialog.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedItem = {
      supplier_category_id: item.supplier_category_id,
      supplier_category_name: categoryName,
      supplier_category_update_date: new Date(updatedDate).toLocaleDateString(
        "en-GB"
      ),
      supplier_category_update_user_id: "EMP-00001",
    };
    try {
      const response = await axios.put(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/supplierCategory/${item.supplier_category_id}`,
        updatedItem,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Response:", response.data);
      alert("Category successfully updated");
      refreshData();
      onClose();
    } catch (error) {
      console.error("Error updating data:", error);
      alert("Failed to update category");
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center z-50">
      <div
        id="dialogBox"
        className="bg-gray-800 bg-opacity-75 fixed inset-0"
      ></div>
      <div className="bg-background rounded-[24px] p-8 z-10 w-[50%] h-[75%]">
        <div className="ml-[97%]">
          <button
            onClick={onClose}
            className="absolute flex justify-center border-none rounded-[22px] bg-clsDialog w-[30.359px] h-[28px] flex-shrink-0"
          >
            <span className="text-white font-bold text-xl">X</span>
          </button>
        </div>
        <div>
          <h2 className="text-addBtn font-poppins font-semibold text-[26px] text-center mt-4">
            Edit Category
          </h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col mt-4">
            <label className="font-bold mb-2">Category Name:</label>
            <input
              type="text"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              className="border border-gray-300 rounded-lg px-2 py-2"
            />
          </div>
          <div className="flex flex-col mt-4">
            <label className="font-bold mb-2">Updated Date:</label>
            <input
              type="date"
              value={updatedDate}
              onChange={(e) => setUpdatedDate(e.target.value)}
              className="border border-gray-300 rounded-lg px-2 py-2"
            />
          </div>
          <div className="flex justify-end mt-4">
            <button
              type="submit"
              className="bg-primary text-white font-bold py-2 px-4 rounded-[22px]"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CatDialogEdit;
