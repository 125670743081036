import React, { useEffect, useState } from "react";
import { IoAdd } from "react-icons/io5";
import { FaEdit, FaTrash } from "react-icons/fa"; // Import icons for edit and delete
import axios from "axios";

const Chart_account = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
  const [seletedValue, setSeletedValue] = useState({
    account_name: "",
    account_code: "",
    root_type: "",
    report_type: "",
    account_type_id: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([

  ]);
  const [allAccountTypes, setAllAccountTypes] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleRowSelect = (id) => {
    if (selectedRow === id) {
      setSelectedRow(null);
      setDropdownVisible(false);
    } else {
      setSelectedRow(id);
      setDropdownVisible(true);
    }
  };

  const togglePopup = () => {
    // Implement your togglePopup logic here
  };

  const handleEdit = () => {
    console.log("Edit row: ", selectedRow);
  };

  const handleDelete = () => {
    console.log("Delete row: ", selectedRow);
  };

  const rowsPerPage = 5;
  const totalPages = Math.ceil(tableData.length / rowsPerPage);
  const currentRows = tableData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const getAccountTypes = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/account/getFirstAndSecondLevelAccTypes`
      );
      console.log(response.data);

      // Transform the data to the desired format
      const formattedData = response.data.map((item) => ({
        value: item.account_id,
        label: item.account_name,
      }));

      setAllAccountTypes(formattedData);


      // console.log(formattedData);
    } catch (error) {
      console.error("Error fetching sample IDs:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setSeletedValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getTableData = async () => {
    try {
      // Construct the parameters object
      const params = {};

      if (seletedValue.account_name) {
        params.account_name = seletedValue.account_name;
      }
      if (seletedValue.account_code) {
        params.account_code = seletedValue.account_code;
      }
      if (seletedValue.root_type) {
        params.root_type = seletedValue.root_type;
      }

      if (seletedValue.report_type) {
        params.report_type = seletedValue.report_type;
      }
      if (seletedValue.account_type_id) {
        params.account_type_id = seletedValue.account_type_id;
      }
   
      // Make the GET request with query parameters
      const response = await axios.get(
        `${backendUrl}/account/getAllAccountsByFilters`, 
        {
          params, // Query parameters
        }
      );

    
      const data = response.data

      console.log(data)


      // setTableData(data);
    } catch (error) {
      console.error("Error fetching sample details:", error);
    }
  };

  useEffect(() => {
    getAccountTypes();
  }, []);

  useEffect(() => {
    getTableData();
  }, [seletedValue]);

  return (
<div className="mx-10 mt-5 text-xl">
  <div className="overflow-visible">
    <div className="flex items-center justify-between mt-6">
      <div>
        <p className="text-[30px] font-semibold text-black">Chart Account</p>
      </div>
    </div>

    <div className="mt-5">
      <div className="flex flex-col gap-4 mt-5 md:flex-row md:items-center md:justify-between">
        <div className="flex flex-col md:flex-row md:items-center md:gap-4">
          {/* Search Box for Account Name */}
          <div className="w-full mt-6 md:w-[200px]">
            <input
              type="text"
              placeholder="Account Name"
               name="account_name"
              value={seletedValue.account_name}  
              onChange={handleChange}
              className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
            />
          </div>

          {/* Search Box for Account Code */}
          <div className="w-full mt-6 md:w-[200px]">
            <input
              type="text"
              placeholder="Account Code"
              name="account_code"
              value={seletedValue.account_code}
              onChange={handleChange}
              className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
            />
          </div>

          {/* Root Type */}
          <div className="w-full md:w-[200px] relative">
            <label
              htmlFor="root_type"
              className="block mb-2 text-sm font-medium text-black"
            >
              Root Type
            </label>
            <select
              id="root_type"
              name="root_type"
              className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
              value={seletedValue.root_type}
              onChange={handleChange}
            >
              <option value=""></option>
              <option value="Asset">Asset</option>
              <option value="Liability">Liability</option>
              <option value="Equity">Equity</option>
              <option value="Revenue">Revenue</option>
              <option value="Expense">Expense</option>
            </select>
          </div>

          {/* Report Type */}
          <div className="w-full md:w-[200px] relative">
            <label
              htmlFor="root_type"
              className="block mb-2 text-sm font-medium text-black"
            >
              Report Type
            </label>
            <select
              name="report_type"
              className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black overflow-y-auto max-h-40 scrollbar-thin scrollbar-thumb-black scrollbar-track-gray-100"
              value={seletedValue.report_type}
              onChange={handleChange}
            >
              <option value=""></option>
              <option value="Balance Sheet">Balance Sheet</option>
              <option value="Profit & Loss">Profit & Loss</option>
            </select>
          </div>

          {/* Account Type */}
          <div className="w-full md:w-[250px]">
            <label
              htmlFor="root_type"
              className="block mb-2 text-sm font-medium text-black"
            >
              Account Type
            </label>
            <select
              name="account_type_id"
              className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
              value={seletedValue.account_type_id}
              onChange={handleChange}
            >
              <option value=""></option>
              {allAccountTypes.map((type) => (
                <option key={type.value} value={type.value}>
                  {type.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Right-Side Button or Dropdown */}
        <div className="relative flex items-center gap-4 md:mt-0">
          {selectedRow ? (
            // Action Dropdown
            <div className="relative">
              <button
                onClick={() => setDropdownVisible(!dropdownVisible)}
                className="px-4 py-2 text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
              >
                Actions
              </button>
              {dropdownVisible && (
                <div className="absolute right-0 z-20 mt-2 bg-white border border-black rounded shadow-lg">
                  <button
                    onClick={handleEdit}
                    className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                  >
                    <FaEdit className="mr-2" /> Edit
                  </button>
                  <button
                    onClick={handleDelete}
                    className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                  >
                    <FaTrash className="mr-2" /> Delete
                  </button>
                </div>
              )}
            </div>
          ) : (
            // Add Button
            <button
              className="flex items-center gap-2 px-4 py-2 text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
              onClick={togglePopup}
            >
              <IoAdd className="text-xl" />
              Add Button
            </button>
          )}
        </div>
      </div>
    </div>
  </div>

  <div className="my-4 overflow-x-auto mt-20">
    {/* TailwindCSS Table */}
    <table className="min-w-full bg-white border border-black mt-12">
      <thead>
        <tr>
          <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black">
            <input
              type="checkbox"
              className="mr-2 transform scale-150" // Increased size of the checkbox
            />
          </th>
          <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black">
            Account Code
          </th>
          <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black">
            Account Name
          </th>
          <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black">
            Sample Type
          </th>
          <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black">
            Status
          </th>
          <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black">
            Root Type
          </th>
          <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black">
            Account Type ID
          </th>
        </tr>
      </thead>
      <tbody>
        {currentRows.map((row, index) => (
          <tr
            key={index}
            className={`${
              selectedRow === row.id ? "bg-gray-100" : ""
            } hover:bg-gray-50`}
            onClick={() => handleRowSelect(row.id)}
          >
            <td className="px-6 py-4 border-b border-black">
              <input
                type="checkbox"
                className="mr-2 transform scale-150" // Increased size of the checkbox
              />
            </td>
            <td className="px-6 py-4 border-b border-black">
              {row.accountCode}
            </td>
            <td className="px-6 py-4 border-b border-black">
              {row.accountName}
            </td>
            <td className="px-6 py-4 border-b border-black">
              {row.sampleType}
            </td>
            <td className="px-6 py-4 border-b border-black">
              {row.status}
            </td>
            <td className="px-6 py-4 border-b border-black">
              {row.rootType}
            </td>
            <td className="px-6 py-4 border-b border-black">
              {row.accountTypeId}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>

  <div className="flex justify-center mt-4">
    <button
      onClick={() => handlePageChange(currentPage - 1)}
      className="px-4 py-2 mx-2 text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
      disabled={currentPage === 1}
    >
      Previous
    </button>
    <span className="px-4 py-2 text-black">
      Page {currentPage} of {totalPages}
    </span>
    <button
      onClick={() => handlePageChange(currentPage + 1)}
      className="px-4 py-2 mx-2 text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
      disabled={currentPage === totalPages}
    >
      Next
    </button>
  </div>
</div>

  );
};

export default Chart_account;
